import { Component, Prop } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import assetsModule from '@/store/modules/assetsModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import gptModule from '@/store/modules/gptModule';
import { getNameByEmail } from '@/utils/users';
import workflowModule from '@/store/modules/workflowModule';
import { getComponent, getConfigEnv } from '@/utils/helpers';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { mixins } from 'vue-class-component';
import {
  TASQ_WAITING_ON_DAYS_LEFT,
  TASQ_WAITING_ON_OVERDUE,
} from '@/lib/constants';
import GptMixin from '../gpt/GptMixin';

@Component({
  components: {
    TasqMiniView: () => getComponent('tasqs/TasqMiniView'),
  },
})
export default class TasqItem extends mixins(GenericMixin, GptMixin) {
  @Prop({ type: String, required: false }) tasqId?: string;

  @Prop({ type: Object, required: false }) source?: TasqJob;

  @Prop({ type: String, required: false }) tasqWellName?: string;

  @Prop({ type: Boolean, required: false }) tasqCompleted?: string;

  @Prop({ type: Boolean, required: false }) tasqProducing?: string;

  @Prop({ type: String, required: false, default: 'tasq' }) viewLevel?: string;

  @Prop({ type: Boolean, required: false }) isPadLevelPage?: boolean;

  @Prop({ type: Boolean, required: false }) isWellLevelPage?: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  setFixHeight?: boolean;

  @Prop({ type: Boolean, required: false }) enableMiniView?: boolean;

  hoverTasqItem = false;

  TASQ_WAITING_ON_DAYS_LEFT = TASQ_WAITING_ON_DAYS_LEFT;

  TASQ_WAITING_ON_OVERDUE = TASQ_WAITING_ON_OVERDUE;

  screenWidth = window.innerWidth;



  get activeManualTasq() {
    const activeTasqs = gptModule.activeManualTasqByWellName(this.tasq.wellName);
    if (activeTasqs && activeTasqs.tasq_count) {
      return !!activeTasqs.tasq_count;
    }
    return false;
  }

  get wellWaitingOn() {
    let padWaitingOn = null;
    const wellWaitingOn = gptModule.waitingOnStatusByWellName(this.tasq.wellName);
    if (this.tasq.padName) {
       padWaitingOn = gptModule.waitingOnStatusByWellName(this.tasq.padName);
    }

    return this.getRcentWaitingOnStatus({ wellWaitingOn, padWaitingOn }) || {};
  }

  get isSmallScreen() {
    return this.screenWidth < 1280;
  }

  get user() {
    return workflowModule.user;
  }





  get wellAlarmsData() {
    // if (this.operator === 'pdc') {
    //  return (workflowModule.alarmByWellName(this.tasq.padName)) || null;
    // }
    return (workflowModule.alarmByWellName(this.tasq.wellName)) || null;
  }

  get isAlarmActive() {
    if (this.operator === 'pdc') {
        return false;
    }
    return this.wellAlarmsData && this.wellAlarmsData.alarm;
  }

  get isShutdownAlarmActive() {
    return this.wellAlarmsData && this.wellAlarmsData.shutdown;
  }

  get alarmTime() {
    return this.wellAlarmsData && this.wellAlarmsData.time ? this.wellAlarmsData.time : null;
  }

  get alarmType() {
    return this.wellAlarmsData && this.wellAlarmsData.alarm_type
    ? this.wellAlarmsData.alarm_type : null;
  }

  get wellTodoCurrentUserStatus() {
    const wellsAssigneStatus = gptModule.wellsAssigneeStatus;

    const wellStatus = wellsAssigneStatus.find(
      (w) => w.wellName === this.tasq.wellName,
    );

    if (wellStatus) {
      return wellStatus;
    }

    return null;
  }

  get todaysActions() {
    const actionedTasqs: any = gptModule.activeNodeActionedTasqs(this.tasq.wellName);
    if (actionedTasqs) {
      return actionedTasqs.count;
    }
    return null;
  }

  formatDateString(dateString) {
    // Get the date.
    const date = new Date(dateString);

    // Get the month, day, and year.
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    // Get the hour, minute, and second.
    const hour = date.getHours();
    const minute = date.getMinutes();
    // const second = date.getSeconds();

    // Make the time in 2 digits.
    const paddedHour = hour.toString().padStart(2, '0');
    const paddedMinute = minute.toString().padStart(2, '0');
    // const paddedSecond = second.toString().padStart(2, '0');

    // Format the date time string in US format with the time in 2 digits.
    const dateTimeString = `${month}/${day}/${year} ${paddedHour}:${paddedMinute}`;

    return dateTimeString;
  }

  get tasqViewsCount() {
    const viewsObject = this.tasq.views;
    let totalViews: number = 0;
    if (Object.keys(viewsObject).length === 0) {
      return totalViews;
    }
    Object.values(viewsObject).forEach((value: any) => {
      totalViews += value;
    });
    return totalViews;
  }

  get hasLoggedInUserViewedTasq() {
    let alreadyViewed = false;
    Object.keys(this.tasq.views).forEach((property) => {
      if (property === this.user.username) {
        alreadyViewed = true;
      }
    });
    return alreadyViewed;
  }

  get isLoadingTasqResonses() {
    return tasqsListModule.isLoadingTasqResponses;
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  get tasq(): any | undefined {
    const currentRouteName: any = this.$route.name;
    if (
      this.source
      && this.source.wellName
    ) {
      return this.source;
    }
    if (this.tasqId === null) {
      return undefined;
    }
    return tasqsListModule.tasqById(this.tasqId!);
  }

  get routeQueryType() {
    return this.$route.query.type;
  }


  get operator() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }


  get didCheckTasq() {
    if (this.tasq != null) {
      return tasqsListModule.checkedTasqs.includes(this.tasq!.id);
    }
    return false;
  }

  get checkedTasqList() {
    return tasqsListModule.checkedTasqs || [];
  }

  get waitingOnStartDate() {
    const date: any = new Date(this.wellWaitingOn.start_date);
    date.setHours(0, 0, 0);
    return date;
  }

  get waitingOnEndDate() {
    const date: any = new Date(this.wellWaitingOn.end_date);
    date.setHours(0, 0, 0);
    return date;
  }

  get waitingOnEndDateWithBuffer() {
    const date: any = new Date(this.wellWaitingOn.end_date);
    date.setHours(0, 0, 0);
    date.setDate(date.getDate() + 3);
    return date;
  }

  get currentDate() {
    const date: any = new Date();
    date.setHours(0, 0, 0);
    return date;
  }

  get percentageForWaitingOn() {
    const oneDay = 24 * 60 * 60 * 1000;
    if (this.wellWaitingOn.start_date && this.wellWaitingOn.end_date) {
      const differenceStartToCurrent = Math.ceil(
        (this.currentDate - this.waitingOnStartDate) / oneDay,
      );
      if (
        this.currentDate < this.waitingOnStartDate
        || differenceStartToCurrent < 1
      ) {
        return 0;
      }
      const differenceStartToEnd = Math.ceil(
          Math.abs(this.waitingOnEndDate - this.waitingOnStartDate) / oneDay,
        ) + 1;
      const result = (differenceStartToCurrent / differenceStartToEnd) * 100;
      if (result > 100) {
        return 100;
      }
      return result;
    }
    return 0;
  }

  get progressBarToolTipText() {
    const oneDay = 24 * 60 * 60 * 1000;
    let text = '';
    if (this.currentDate >= this.waitingOnEndDate) {
      text = 'Overdue';
    } else if (this.waitingOnStartDate > this.currentDate) {
      const difference = Math.round(
        Math.abs((this.waitingOnStartDate - this.currentDate) / oneDay),
      );
      text = `Starts in ${difference} days`;
      if (difference === 1) {
        text = `Starts in ${difference} day`;
      }
    } else if (
      this.currentDate > this.waitingOnStartDate
      && this.currentDate < this.waitingOnEndDate
    ) {
      const difference = Math.round(
        Math.abs((this.waitingOnEndDate - this.currentDate) / oneDay),
      );
      text = `${difference} days left`;
      if (difference === 1) {
        text = `${difference} day left`;
      }
    }
    return text;
  }

  get waitingOnReason() {
    if (this.wellWaitingOn) {
      return this.wellWaitingOn.body;
    }
    return '';
  }

  get isScheduledLater() {
    return false;
  }



  selectedAssignee: any = [];





  userInitials(name) {
    if (name && name.length && name.trim().length > 0) {
      const fullName = name;
        const names = fullName.trim().split(' ');
        if (names.length >= 2) {
            const firstInitial = names[0][0] || '';
            const secondInitial = names[1][0] || names[0][1] || '';
            return `${firstInitial}${secondInitial}`;
        } else if (names.length === 1) {
            const firstInitial = names[0][0] || '';
            const secondInitial = names[0][1] || '';
            return `${firstInitial}${secondInitial}`;
        }
    }
    return '';
}


  get iconSize() {
    return this.screenWidth > 1279 ? 14 : 10;
  }



  handleResize() {
    this.screenWidth = window.innerWidth;
  }

  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    this.$nextTick(() => {

      this.selectedAssignee = [
        {
          value: this.tasq.username,
          text: getNameByEmail(this.tasq.username),
          initials: this.userInitials(getNameByEmail(this.tasq.username)),
        },
      ];
    });
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  checkTasq() {
    if (this.tasq != null && this.tasq.id) {
      tasqsListModule.setIsBatchResponding(false);
      tasqsListModule.checkTasq(this.tasq!.id);
      tasqsListModule.setBatchResponseEnabled();
    }
    if (this.checkedTasqList && this.checkedTasqList.length === 1) {
      const currentName: any = this.$route.name;
      this.$router.push({ name: currentName });
      tasqsListModule.setIsLoadingTasqResponses(false);
      tasqsListModule.setActiveTasq('');
      tasqsListModule.setIsEditing(true);
    } else if (this.checkedTasqList.length === 0) {
      this.closeEditToolbar();
    }
  }

  closeEditToolbar() {
    tasqsListModule.setIsEditing(false);
    tasqsListModule.setIsBatchResponding(false);
    tasqsListModule.setIsBatchReassign(false);
    tasqsListModule.setActiveTasq('');
    tasqsListModule.resetCheckedTasqs();
  }

    openLink(wellName) {
    const url = `${window.location.origin}/Tasqs/${encodeURI(
      wellName
    )}?type=producing&view=tasq`;
    window.open(url, '_blank');
  }

  async goToTasqPage(type) {
    if (this.isEditing) {
      this.checkTasq();
      return;
    }


    console.log('type');

    console.log(type);


    tasqsListModule.setTasqDataLoadingFeedback(true);
    this.$nextTick(() => {
      const currentRouteName: any = this.$route.name;
      this.openLink(this.tasq.wellName);
    // if (type === 'producing' || type === '') {
    //   this.$router.push({
    //     name: 'Tasqs',
    //     params: {
    //       id: this.tasq?.wellName || '',
    //     },
    //     query: { type: 'producing', view: this.viewLevel },
    //   });
    //   tasqsListModule.setIsEditing(false);
    //   assetsModule.setActiveProducingTasq('');
    //   tasqsListModule.setActiveTasqV2(this.tasq);
    //   tasqsListModule.setActiveTasqExplicit(this.tasq);
    // } else {
    //   assetsModule.setActiveTasqID(this.tasq?.id || '');
    //   this.$router.p({
    //     name: 'Tasqs',
    //     params: {
    //       id: this.tasq?.id || '',
    //     },
    //     query: { type: 'id', view: this.viewLevel },
    //   });
    //   tasqsListModule.setIsEditing(false);
    //   assetsModule.setActiveProducingTasq('');
    //   // tasqsListModule.setActiveTasqExplicit(this.tasq);
    // }
    });
  }
}
